

























































































































































































import { InputSetups } from '@/mixins/input-setups'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import HandleUserBioField from "@/includes/logic/Modules/components/filters/fields/HandleUserBioField.vue";
import WebLinksFilterHandlerModuleBuilder from './WebLinksFilterHandlerModuleBuilder';

import ModuleConfigSettings from '../../../components/ModuleConfigSettings.vue'
import ModuleConfigSettingsWrapper from '../../../components/ModuleConfigSettingsWrapper.vue'
import FilterJournal from "../../../components/filters/FilterJournal.vue";
import FilterCommonSettings from "../../../components/filters/FilterCommonSettings.vue";
import FilterNewUsersSettings from "../../../components/filters/FilterNewUsersSettings.vue";
import FilterRemoveEditedSetting from "../../../components/filters/FilterRemoveEditedSetting.vue";
import FilterExtraSettings from "../../../components/filters/FilterExtraSettings.vue";
import FilterResetPunishSettings from '../../../components/filters/FilterResetPunishSettings.vue'
import FilterSettingLayout from "../../../components/filters/layout/FilterSettingLayout.vue";

import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue';
import BlackWhiteListInput
  from 'piramis-base-components/src/components/Pi/fields/BlackWhiteListInput/BlackWhiteListInput.vue';

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    HandleUserBioField,
    FilterSettingLayout,
    FilterExtraSettings,
    FilterRemoveEditedSetting,
    FilterResetPunishSettings,
    FilterNewUsersSettings,
    FilterCommonSettings,
    FilterJournal,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    SwitchInput,
    BlackWhiteListInput
  },
})
export default class WebLinksFilterHandlerModuleView extends Mixins(InputSetups, ModuleBindings) {
  @VModel() module!: WebLinksFilterHandlerModuleBuilder

  get en_sites_top_100() {
    return this.$store.state?.AdminTemplate?.runtime_config?.en_sites_top_100 ?? []
  }

  get ru_sites_top_100() {
    return this.$store.state?.AdminTemplate?.runtime_config?.ru_sites_top_100 ?? []
  }

}
